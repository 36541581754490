<template>
  <div class="fixed-menu z-10 sticky">
    <div class="hidden lg:block relative banner-nav bg-purple -mb-20 py-4 px-4 sm:px-8 lg:px-12">
      <div class="relative flex justify-center lg:justify-end z-30">
        <div class="flex uppercase ml-8 space-x-8">
          <a href="Tel:02892688079" class="flex text-base text-white px-4 py-1 font-bold justify-center sm:justify-start">
            <icon-phone aria-label="phone" class="w-4 h-4 mr-2 icon-nav text-white mt-1"></icon-phone> 028 9268 8079
          </a>
          <a class="text-base text-white px-4 py-1 font-bold" href="https://www.myacebook.net/mobile/user_login.php" target="blank">Book a court</a>
          <a class="text-base py-1 text-purple bg-white uppercase px-6 font-bold join-btn hover:text-white hover:bg-purple-300 transition duration-150 ease-in-out" href="/membership">JOIN</a>
        </div>
      </div>
    </div>
    <div class="bg-purple-500 lg:bg-transparent">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppFixedHeader',
  data () {
    return {
      observer: null
    }
  },
  created () {
    this.observer = new IntersectionObserver(
      this.onElementObserved,
      { root: this.$el, threshold: [1] }
    )
  },
  mounted () {
    this.observer.observe(this.$el)
  },
  beforeDestroy () {
    this.observer.disconnect()
  },
  methods: {
    onElementObserved ([e]) {
      // e.target.classList.toggle('shadow-lg', e.intersectionRatio < 1),
      // e.target.classList.toggle('bg-opacity-75', e.intersectionRatio < 1)
      // e.target.classList.toggle('border-b-8', e.intersectionRatio < 1)
    }
  }
}
</script>
