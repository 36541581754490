<template>
  <div v-swiper:BannerSlider="settings" style="overflow:inherit">
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'BannerSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        direction: "horizontal",
        autoplay: { delay: 7000 },
        loop: true,
        loopAdditionalSlides: 3,
        spaceBetween: 50
      }
    }
  }
}
</script>
