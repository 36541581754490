<template>
  <div v-swiper:AboutSlider="settings" style="overflow:inherit" class="pb-24">
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="flex gap-x-20 justify-center mt-8">
      <div class="gallery-prev relative z-10 outline-none w-auto block">
        <svg class="text-green hover:text-purple-300  w-8 h-8 outline-none transition-colors" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
        </svg>
      </div>
      <div class="gallery-next relative z-10 outline-none w-auto block">
        <svg class="text-green hover:text-purple-300 w-8 h-8 transition-colors" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AboutSlider',
  data () {
    return {
      settings: {
        slidesPerView: 2,
        direction: "horizontal",
        autoplay: { delay: 3000 },
        loop: true,
        loopAdditionalSlides: 3,
        spaceBetween: 10,
        navigation: {
         nextEl: '.gallery-next',
         prevEl: '.gallery-prev',
        },
        breakpoints: {
          600: {
            slidesPerView: 4,
            spaceBetween: 20,
          }
        }
      }
    }
  }
}
</script>
