<template>
  <div>
    <div class="lg:text-2xl flex cursor-pointer" @click="expanded = !expanded">
      <span class="text-2xl text-black"><span class="font-bold text-purple-300 text-3xl">Q. </span>{{ title }}</span>
      <span class="ml-2 w-6 h-6 lg:w-8 lg:h-8" :class="icon">
        <svg class="w-full text-purple-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path v-if="this.expanded"  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
          <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
      </span>
    </div>
      <div ref="accordion" class="accordion" :class="{expanded: expanded}":style="[expanded ? { height : computedHeight } : {}]">
        <div class="prose lg:prose-lg">
          <slot />
        </div>
      </div>
  </div>
</template>
<script>
export default {
  name: 'Accordion',
  props: {
    title: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: 'red'
    }
  },
  data () {
    return {
      computedHeight: 0,
      expanded: false
    }
  },
  mounted: function(){
    this.initHeight()
  },
  computed: {
    icon () {
      if (this.color === 'green') {
        return 'text-green-500'
      }else if (this.color === 'purple') {
        return 'text-purple-500'
      }else if (this.color === 'teal') {
        return 'text-teal-500'
      }else {
        return 'text-green-400'
      }
    }
  },
  methods: {
    initHeight: function(){
      this.$refs['accordion'].style.height = 'auto'
      this.$refs['accordion'].style.visibility = 'hidden'
      this.$refs['accordion'].style.display = 'block'
      const height = getComputedStyle(this.$refs['accordion']).height
      this.computedHeight= height
      this.$refs['accordion'].style.position = null
      this.$refs['accordion'].style.visibility = null
      this.$refs['accordion'].style.display = null
      this.$refs['accordion'].style.height = 0
    }
  }
}
</script>
<style lang="scss">
.accordion{
  transition: height .25s ease, opacity .25s ease;
  height: 0;
  opacity: 0;
  overflow: hidden;
  &.expanded{
    opacity: 1;
  }
}
</style>
